<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          {{ $t("editproduct") }}
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <!-- -->
        <div class="row mb-5">
          <div class="col-sm-1" />
          <div class="col-sm-11">
            <h3>
              <i class="bi bi-file-earmark-text" style="font-size: 20px"></i>
              {{ $t("detailproduct") }}
            </h3>
          </div>
        </div>

        <div class="row d-flex justify-content-center">
          <div class="col-sm-5">
            <label for="ProductName" class="required form-label col-sm-5">{{
              $t("productcode")
            }}</label>
            <input
              v-model="form.code"
              type="text"
              class="form-control mb-4"
              id="ProductName"
              name="ProductName"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
          <!-- </div>
        <div class="row d-flex justify-content-center"> -->
          <div class="col-sm-5">
            <label for="barcode" class="form-label col-sm-5">{{
              $t("barcode")
            }}</label>
            <input
              v-model="form.barcode"
              type="text"
              class="form-control mb-4"
              id="barcode"
              name="barcode"
            />
          </div>
        </div>

        <div class="row d-flex justify-content-center">
          <label for="ProductName" class="required form-label col-sm-10">{{
            $t("productname")
          }}</label>
          <div class="col-sm-10">
            <input
              v-model="form.name"
              type="text"
              class="form-control mb-4"
              id="ProductName"
              name="ProductName"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>

        <div class="row d-flex justify-content-center">
          <div class="col-sm-10">
            <label for="retailPrice" class="required form-label col-sm-10">{{
              $t("mainproduct")
            }}</label>

            <select
              class="form-select mb-4"
              v-model="form.itemGroupHeaderId"
              id="validationTooltip01"
              required
            >
              <!-- @change="getSubProduct()" -->
              <option v-for="item in itemGroup" :key="item" :value="item.id">
                {{ item.code }} |
                {{ item.name }}
              </option>
            </select>
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>
        <!-- <div class="row d-flex justify-content-center">
          <div class="col-sm-10">
            <label for="retailPrice" class="required form-label col-sm-10">{{
              $t("producttype")
            }}</label>

            <select
              class="form-select mb-4"
              v-model="form.subProductGroupId"
              id="validationTooltip01"
              required
            >
              <option v-for="item in subProduct" :key="item" :value="item.id">
                {{ item.sub_product_group_code }} |
                {{ item.sub_product_group_name }}
              </option>
            </select>
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div> -->

        <div class="row d-flex justify-content-center">
          <div class="col-sm-5 cm">
            <label for="retailPrice" class="form-label col-sm-10">{{
              $t("width")
            }}</label>
            <input
              v-model="form.product_width"
              class="form-control mb-4"
              id="retailPrice"
              name="retailPrice"
              type="number"
              onchange="(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)"
              min="0.00"
              step="0.01"
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
          <div class="col-sm-5 cm">
            <label for="retailPrice" class="form-label col-sm-10">{{
              $t("long")
            }}</label>
            <input
              v-model="form.product_long"
              class="form-control mb-4"
              id="retailPrice"
              name="retailPrice"
              type="number"
              onchange="(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)"
              min="0.00"
              step="0.01"
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>

        <div class="row d-flex justify-content-center">
          <div class="col-sm-5 cm">
            <label for="retailPrice" class="form-label col-sm-10">{{
              $t("high")
            }}</label>
            <input
              v-model="form.product_high"
              class="form-control mb-4"
              id="retailPrice"
              name="retailPrice"
              type="number"
              onchange="(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)"
              min="0.00"
              step="0.01"
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
          <div class="col-sm-5"></div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-sm-5 lb">
            <label for="retailPrice" class="form-label col-sm-10">{{
              $t("volume")
            }}</label>
            <input
              v-model="form.volume"
              class="form-control mb-4"
              id="retailPrice"
              name="retailPrice"
              type="number"
              onchange="(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)"
              min="0.0000"
              step="0.01"
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
          <div class="col-sm-5 gg">
            <label for="retailPrice" class="form-label col-sm-10">{{
              $t("weight")
            }}</label>
            <input
              v-model="form.weight"
              class="form-control mb-4"
              id="retailPrice"
              name="retailPrice"
              type="number"
              onchange="(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)"
              min="0.0000"
              step="0.01"
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>

        <div class="row d-flex justify-content-center mb-4">
          <div class="col-sm-5 start">
            <label for="retailPrice" class="form-label col-sm-10">{{
              $t("score")
            }}</label>
            <input
              v-model="form.score"
              class="form-control mb-4"
              id="retailPrice"
              name="retailPrice"
              type="number"
              onchange="(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)"
              min="0.0000"
              step="0.01"
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
          <div class="col-sm-5">
            <label for="validationTooltip01" class="form-label col-sm-10">{{
              $t("status")
            }}</label>
            <select v-model="form.status" class="form-select">
              <div class="invalid-feedback text-end">กรุณากรอก</div>
              <option value="ขายปกติ">ขายปกติ</option>
              <option value="ยกเลิก">ยกเลิก</option>
            </select>
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>

          <!-- <div class="col-sm-5">
            <label for="retailPrice" class="form-label col-sm-10">สถานะ</label>
            <select
              v-model="form.status"
              type="text"
              min="0"
              class="form-control mb-4"
              id="retailPrice"
              name="retailPrice"
            >
            <div class="invalid-feedback text-end">กรุณากรอก</div>
              <option value="คลังหลัก">ขายปกติ</option>
              <option value="คลังรอง">ยกเลิก</option>
          </select>
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div> -->
        </div>

        <div class="row d-flex justify-content-center">
          <div class="col-sm-10">
            <div class="row d-flex justify-content-center">
              <div class="col-sm-4 mb-4">
                <label
                  class="form-check form-check-sm form-check-custom form-check-solid me-5"
                >
                  <el-checkbox v-model="form.tax" type="checkbox" value="1" />
                  <span class="form-check-label"> {{ $t("tax") }} </span>
                </label>
              </div>
              <div class="col-sm-4 mb-4">
                <label
                  class="form-check form-check-sm form-check-custom form-check-solid me-5"
                >
                  <el-checkbox v-model="form.give" type="checkbox" value="1" />
                  <span class="form-check-label"> {{ $t("free") }} </span>
                </label>
              </div>
              <div class="col-sm-4 mb-4">
                <label
                  class="form-check form-check-sm form-check-custom form-check-solid me-5"
                >
                  <el-checkbox
                    v-model="form.favorite"
                    type="checkbox"
                    value="1"
                  />
                  <span class="form-check-label" style="color: red">{{
                    $t("productstosell")
                  }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <hr class="mt-10 mb-8" style="color: LightGrey" />

        <div class="row mb-5">
          <div class="col-sm-1" />
          <div class="col-sm-11">
            <h3>
              <i class="bi bi-cash-coin" style="font-size: 20px"></i>
              {{ $t("unitproduct") }}
            </h3>
          </div>
        </div>

        <div class="row d-flex justify-content-center">
          <div class="col-sm-10">
            <div class="row d-flex justify-content-center">
              <div class="col-sm-4 mb-4">
                <label for="retailPrice" class="form-label col-sm-10">{{
                  $t("barcode")
                }}</label>
                <input
                  v-model="unit.barcode"
                  type="number"
                  min="0"
                  class="form-control mb-4"
                  id="retailPrice"
                  name="retailPrice"
                />
                <div class="invalid-feedback text-end">กรุณากรอก</div>
              </div>
              <div class="col-sm-4 mb-4">
                <label for="retailPrice" class="form-label col-sm-10">{{
                  $t("unitname")
                }}</label>
                <select
                  class="form-select mb-4"
                  v-model="unit.productUnitId"
                  id="validationTooltip01"
                >
                  <option v-for="item in units" :key="item" :value="item.id">
                    {{ item.code }} |
                    {{ item.name }}
                  </option>
                </select>
                <div class="invalid-feedback text-end">กรุณากรอก</div>
              </div>
              <div class="col-sm-4 mb-4">
                <label for="retailPrice" class="form-label col-sm-10">{{
                  $t("packagesize")
                }}</label>
                <input
                  v-model="unit.size"
                  class="form-control mb-4"
                  id="retailPrice"
                  name="retailPrice"
                  type="number"
                  @change="setdefaultsize()"
                  min="0"
                  step="1"
                />
                <div class="invalid-feedback text-end">กรุณากรอก</div>
              </div>
              <div class="row d-flex justify-content-center">
                <div class="col-sm-4 mb-4">
                  <label
                    class="form-check form-check-sm form-check-custom form-check-solid me-5"
                  >
                    <el-checkbox
                      v-model="unit.default"
                      type="checkbox"
                      value="true"
                    />
                    <span class="form-check-label">{{ $t("default") }}</span>
                  </label>
                </div>

                <div class="col-sm-4 mb-4">
                  <label
                    class="form-check form-check-sm form-check-custom form-check-solid me-5"
                  >
                    <el-checkbox
                      v-model="unit.bill"
                      type="checkbox"
                      value="1"
                    />
                    <span class="form-check-label"> {{ $t("bill") }} </span>
                  </label>
                </div>
                <div class="col-sm-4 mb-4"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="row d-flex justify-content-center">
          <div class="col-sm-4">
            <label for="retailPrice" class="form-label col-sm-10">{{
              $t("unitprice")
            }}</label>
            <input
              v-model="unit.unit_price"
              type="text"
              min="0"
              class="form-control mb-4"
              id="retailPrice"
              name="retailPrice"
              v-mask-decimal="2"
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
          <div class="col-sm-4">
            <label for="retailPrice" class="form-label col-sm-10">{{
              $t("cost")
            }}</label>
            <input
              v-model="unit.cost"
              type="text"
              min="0"
              class="form-control"
              id="retailPrice"
              name="retailPrice"
              v-mask-decimal="2"
            />
          </div>

          <div class="col-sm-2" style="text-align: end">
            <!-- <label for="retailPrice" class="form-label col-sm-10"></label> -->
            <button
              type="button"
              class="btn btn-sm btn-primary mt-10"
              style="font-size: 14px; font-weight: bold"
              @click="appendTable()"
            >
              {{ $t("add") }}
            </button>
          </div>
        </div>

        <!-- <div class="col-sm-11">
          <div class="row d-flex">
            <div class="col-sm-12" style="text-align: end">
              <button
                type="button"
                class="btn btn-sm btn-primary"
                style="font-size: 14px; font-weight: bold"
                @click="appendTable()"
              >
                เพิ่ม
              </button>
            </div>
          </div>
          <div class="col-sm-2">
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
            >
              แก้ไข
            </button>
          </div>
          <div class="col-sm-2">
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
            >
              ลบ
            </button>
          </div>
        </div> -->

        <hr class="mt-10 mb-8" style="color: LightGrey" />

        <div class="justify-content-center" style="text-align: -webkit-center">
          <div class="card-body col-sm-11">
            <table
              class="table table-hover table-rounded table-striped border gy-7 gs-7"
            >
              <thead>
                <tr
                  class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 text-center"
                >
                  <th>{{ $t("barcode") }}</th>
                  <th>{{ $t("unitname") }}</th>
                  <th>{{ $t("multiply") }}</th>
                  <th>{{ $t("sellprice") }}</th>
                  <th>{{ $t("cost") }}</th>
                  <th>{{ $t("default") }}</th>
                  <th>{{ $t("bill") }}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in dataItemsShow"
                  :key="index"
                  class="text-center"
                >
                  <td style="width: 180px">
                    <input
                      type="text"
                      class="form-control"
                      v-model="item.barcode"
                    />
                  </td>
                  <td style="width: 180px">
                    <select
                      class="form-select mb-4"
                      v-model="item.productUnitId"
                      id="validationTooltip01"
                    >
                      <option v-for="i in units" :key="i" :value="i.id">
                        {{ i.code }} |
                        {{ i.name }}
                      </option>
                    </select>
                  </td>
                  <td style="width: 180px">
                    <input
                      type="text"
                      class="form-control"
                      v-model="item.size"
                    />
                  </td>
                  <td style="width: 180px">
                    <!-- <input
                      type="text"
                      class="form-control"
                      v-model="item.unit_price"
                    /> -->
                    <span>{{ item.unit_price }} บาท</span>
                    <!-- <span>{{ item.unit_price * item.size }} บาท</span> -->
                  </td>
                  <td style="width: 180px">
                    <input
                      type="text"
                      class="form-control"
                      v-model="item.cost"
                    />
                  </td>
                  <td style="width: 120px">
                    <el-checkbox
                      v-model="item.default"
                      type="checkbox"
                      @change="changeValue(index, item)"
                    />
                  </td>
                  <td style="width: 120px">
                    <el-checkbox v-model="item.bill" type="checkbox" />
                  </td>

                  <td>
                    <div class="row">
                      <!-- <div class="col-sm-6 d-flex justify-content-md-end">
                        <button
                          type="button"
                          class="
                            btn
                            btn-link
                            btn-color-muted
                            btn-active-color-success
                            p-0
                          "
                          @click="goToEdit(index)"
                        >
                          <i class="bi bi-pencil-square"></i>
                        </button>
                      </div> -->
                      <div class="col-sm-6 d-flex">
                        <button
                          type="button"
                          class="btn btn-link btn-color-muted btn-active-color-danger p-0"
                          @click="goToDelete(index)"
                        >
                          <i class="bi bi-trash3-fill"></i>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <div class="card-footer">
          <ul class="pagination">
            <li class="page-item previous disabled">
              <a href="#" class="page-link"><i class="previous"></i></a>
            </li>
            <li v-for="n in 5" :key="n" class="page-item">
              <a class="page-link">{{ n }}</a>
            </li>
            <li class="page-item next">
              <a href="#" class="page-link"><i class="next"></i></a>
            </li>
          </ul>
        </div> -->
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />
      <!-- <div class="col-sm-6">
        <button
          type="button"
          class="btn btn-sm btn-light"
          style="font-size: 14px; font-weight: bold"
        >
          click to Upload
        </button>
      </div> -->

      <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-10">
            <button
              type="button"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="submit()"
              :disabled="loading"
            >
              <div
                v-if="loading"
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              ></div>
              {{ $t("save") }}
            </button>
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
              @click="cancel"
            >
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import Loader from "../../components/loaderUpdate.vue";
import whApi from "@/api/warehouse/";

export default {
  setup() {
    document.title = "J&N | แก้ไขสินค้า";
  },
  components: {
    Loader,
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    isSubmit: false,
    unit: {
      // default: true,
      bill: true,
    },
    form: {
      // selectMainProduct: "",
      // selectSubProduct: "",
      // product_name: "",
      // product_desc: "",
      // product_barcode: "",
      // product_pog: "",
      // product_cost_price: "",
      // product_wholesale_price: "",
      // product_retail_price: "",
      // product_promotion_price: "",
      // product_min_stock: "",
      // product_max_stock: "",
      // product_bring_qty: "",
      // status: "0",
      // approved_by: "",
    },
    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,

    itemGroup: [],
    subProduct: [],
    typeProduct: [],
    dataItemsShow: [],
    units: [],
    subProductItem: [],
  }),

  async created() {
    await this.getMainProduct();
    // this.getTypeProduct();
    await this.getOne();
    // await this.getSubProduct();
    await this.getProductPriceById();
    await this.getAllUnit();

    await this.checkQueryParam();
  },

  methods: {
    setdefaultsize() {
      this.dataItemsShow.forEach((element) => {
        if (element.productUnitId == 14) {
          this.unit.unit_price = element.unit_price * this.unit.size;
        }
      });
    },
    changeValue(index, item) {
      this.dataItemsShow.forEach((element) => {
        element.default = false;
      });
      this.dataItemsShow[index].default = true;
    },
    appendTable() {
      this.dataItemsShow.push({
        barcode: this.unit.barcode,
        productUnitId: this.unit.productUnitId,
        size: this.unit.size,
        default: this.unit.default,
        bill: this.unit.bill,
        unit_price: this.unit.unit_price,
        cost: this.unit.cost,
      });
      console.log("this.dataItemsShow", this.dataItemsShow);
    },
    goToDelete(index) {
      this.dataItemsShow.splice(index, 1);
    },
    async getAllUnit() {
      this.form.companyId = parseInt(localStorage.getItem("companyId"));
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.unit.getAll(this.form.companyId);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.units = responseData.data;
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    checkQueryParam() {
      if (
        this.$route.query.editByRecive &&
        this.$route.query.editByRecive === "true"
      ) {
        this.$router
          .replace({
            query: {
              id: this.$route.query.id,
            },
          })
          .then(() => {
            this.$router.go();
          });
      }
    },
    async getMainProduct() {
      let responseData = [];
      try {
        responseData = await whApi.itemGroup.getAll(
          localStorage.getItem("companyId")
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.itemGroup = responseData.data;
        // this.form.itemGroupHeaderId = responseData.data[0].id;
        // this.form.selectMainProduct = responseData.data[0].id;
      }
    },
    async getSubProduct() {
      let responseData = [];
      try {
        responseData = await whApi.subProduct.getAll(
          this.form.itemGroupHeaderId
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.subProduct = responseData.data;
        // this.form.subProductGroupId = responseData.data[0].id;
      }
    },
    async getTypeProduct() {
      let responseData = [];
      try {
        responseData = await whApi.typeProduct.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.typeProduct = responseData.data;
        // this.form.productTypeId = responseData.data[0].id;
      }
    },
    async getOne() {
      this.loading = true;
      let getOneResponse = {};
      try {
        getOneResponse = await whApi.product.getOne(this.$route.query.id);
      } catch (error) {
        console.log(error);
      }
      if (getOneResponse.response_status === "SUCCESS") {
        this.form = getOneResponse.data;
        // this.form = {
        //   ...getOneResponse.data,
        //   selectMainProduct: getOneResponse.data.itemGroupHeaderId,
        //   selectSubProduct: getOneResponse.data.subProductGroupId,
        // };
        this.loading = false;
      }
    },
    async getProductPriceById() {
      this.loading = true;
      let getOneResponse = {};
      try {
        getOneResponse = await whApi.product.getProductPrice(
          this.$route.query.id
        );
      } catch (error) {
        console.log(error);
      }
      if (getOneResponse.response_status === "SUCCESS") {
        this.dataItemsShow = getOneResponse.data;
        this.loading = false;
      }
    },

    async submit() {
      Swal.fire({
        icon: "question",
        title: `${this.$t("want2submit")} ${this.$t("ornot")} ?`,
        confirmButtonText: `${this.$t("ok")} `,
        cancelButtonText: `${this.$t("cancel")} `,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let updateResponse = [];

          await this.validateForm();
          await this.checkFormIsEmpty();
          if (this.dataItemsShow.length == 0) {
            Swal.fire({
              icon: "warning",
              title: `ข้อมูลไม่ครบถ้วน`,
              text: `กรุณาใส่ราคาสินค้า`,
              showConfirmButton: false,
              timer: 1500,
            });
          }
          this.isSubmit = true;

          this.form = {
            ...this.form,
            product_high: this.form.product_high * 1,
            product_long: this.form.product_long * 1,
            product_width: this.form.product_width * 1,
            product_prices: this.dataItemsShow,
            companyId: parseInt(localStorage.getItem("companyId")),
          };
          console.log("this.form", this.form);
          if (this.isValid) {
            this.loadingUpdate = true;
            updateResponse = await whApi.product.update(
              this.$route.query.id,
              this.form
            );
            if (updateResponse.response_status === "SUCCESS") {
              Swal.fire({
                icon: "success",
                title: `แก้ไขสำเร็จ`,
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.$router.push({
                  path: "/warehouse/WH4",
                  query: {},
                });
              });
              this.loadingUpdate = false;
            } else {
              Swal.fire({
                icon: "error",
                title: `${updateResponse.message}`,
                showConfirmButton: false,
                timer: 1500,
              });
              this.loadingUpdate = false;
            }
          }
        }
      });
    },
    checkFormIsEmpty() {
      !this.form.code
        ? (this.isValid = false)
        : !this.form.name
        ? (this.isValid = false)
        : !this.form.itemGroupHeaderId
        ? (this.isValid = false)
        : (this.isValid = true);

      let foundDefault = this.dataItemsShow.filter(
        (element) => element.default == true
      );
      if (foundDefault.length > 0) {
        this.isValid = true;
      } else {
        this.isValid = false;
        Swal.fire({
          icon: "warning",
          title: `ไม่ได้ระบุราคา default`,
          text: `กรุณาใส่ราคา default`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.push({
        path: "/warehouse/WH4",
        query: {},
      });
    },
  },
};
</script>
<style>
.cm::after {
  content: "ซม";
}
.lb::after {
  content: "ลบ.ม";
}
.gg::after {
  content: "กก.";
}
.start::after {
  content: "แต้มต่อหน่วยเริ่มต้น";
}
div {
  /* display: inline-block; */
  position: relative;
}

/* position the unit to the right of the wrapper */
div::after {
  position: absolute;
  top: 40px;
  right: 0.9em;
  transition: all 0.05s ease-in-out;
}
</style>